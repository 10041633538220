/* ENV variables for local env */
const ENV = {
  NEXT_PUBLIC_API_BASE: 'https://treezpay-api.dev.swifterhq.com/checkout/v2.0',
  NEXT_PUBLIC_AMPLIFY_REGION: 'us-west-2',
  NEXT_PUBLIC_AMPLIFY_USER_POOL_ID: 'us-west-2_ZmTnZlnbj',
  NEXT_PUBLIC_AMPLIFY_WEB_CLIENT_ID: 'k2i7fvjqm9hfp37b5b0ppr3fq',
  NEXT_PUBLIC_PENDO_KEY: '',
  NEXT_PUBLIC_SENTRY_ENV: 'LOCAL',
  NEXT_PUBLIC_SENTRY_DSN:
    'https://c20667c510d043afbf77594861f145ea@o507283.ingest.sentry.io/5968487',
  NEXT_PUBLIC_FULLSTORY_ORG_ID: '',
  BASE_URL: 'http://localhost:3000',
};

/* Set variables for dev, prod in AWS Amplify console */
const vars = process.env.NODE_ENV !== 'production' ? ENV : process.env;

export const {
  NEXT_PUBLIC_API_BASE: API_BASE,
  NEXT_PUBLIC_AMPLIFY_REGION: AMPLIFY_REGION,
  NEXT_PUBLIC_AMPLIFY_USER_POOL_ID: AMPLIFY_USER_POOL_ID,
  NEXT_PUBLIC_AMPLIFY_WEB_CLIENT_ID: AMPLIFY_WEB_CLIENT_ID,
  NEXT_PUBLIC_PENDO_KEY: PENDO_KEY,
  NEXT_PUBLIC_SENTRY_DSN: SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENV: SENTRY_ENV,
  NEXT_PUBLIC_FULLSTORY_ORG_ID: FULLSTORY_ORG_ID,
  BASE_URL,
} = vars;
