import { BASE_URL } from './config/env';

const style = document.createElement('link');
style.href = `${BASE_URL}/styles/pay.css`;
style.type = 'text/css';
style.rel = 'stylesheet';
document.getElementsByTagName('head')[0].appendChild(style);

function closeSwifterPayModal() {
  document.querySelector('#swifter-pay-iframe')?.remove();
  document.querySelector('#swifter-pay-loader')?.remove();
}

function onIframeLoaded() {
  document.querySelector('#swifter-pay-loader')?.remove();
  return null;
}

/*
 *  Payment widget flow
 *  sessionID _required_
 */
function _init(
  sessionID: string,
  selector: string,
  renderInModal: boolean,
  env: IEnv,
  themeID?: string
) {
  console.log('Will anchor to this selector: ', selector); // skipcq: JS-0002
  const hasIframe = document.getElementById('swifter-pay-iframe');
  if (hasIframe) return;
  const el = document.querySelector(selector);
  const loader = document.createElement('div');
  loader.className = 'loader';
  loader.id = 'swifter-pay-loader';
  if (el) {
    el.append(loader);
  }
  const iframe = document.createElement('iframe');
  iframe.frameBorder = '0';
  iframe.width = '375';
  iframe.id = 'swifter-pay-iframe';
  iframe.src = `${BASE_URL}/?sessionID=${sessionID}&renderInModal=${renderInModal}${
    env ? `&env=${env}` : ''
  }${themeID ? `&themeID=${themeID}` : ''}`;
  iframe.className = renderInModal ? 'isModal' : '';
  iframe.onload = onIframeLoaded();
  if (el) {
    el.append(iframe);
  }
}

/*
 *  Signup widget flow
 *  sessionID _required_
 */
function _initRegistration(
  selector: string,
  renderInModal: boolean,
  env: IEnv,
  themeID?: string
) {
  console.log('Will anchor to this selector: ', selector); // skipcq: JS-0002
  const hasIframe = document.getElementById('swifter-pay-iframe');
  if (hasIframe) return;
  const el = document.querySelector(selector);
  const loader = document.createElement('div');
  loader.className = 'loader';
  loader.id = 'swifter-pay-loader';
  if (el) {
    el.append(loader);
  }
  const iframe = document.createElement('iframe');
  iframe.frameBorder = '0';
  iframe.width = '375';
  iframe.id = 'swifter-pay-iframe';
  iframe.src = `${BASE_URL}/register?renderInModal=${renderInModal}${
    env ? `&env=${env}` : ''
  }${themeID ? `&themeID=${themeID}` : ''}`;
  iframe.className = renderInModal ? 'isModal' : '';
  iframe.onload = onIframeLoaded();
  if (el) {
    el.append(iframe);
  }
}

function respondToIframeMessage(obj: ISwifterPay, event: MessageEvent) {
  if (event.origin.startsWith(BASE_URL as string)) {
    // The data was sent from your site.
    if (
      event.data &&
      event.data.type &&
      event.data.type.startsWith('sp-exit')
    ) {
      const { message } = event.data;
      console.log('Swifter Pay exited due to an error or the user.'); // skipcq: JS-0002
      if (obj && obj.onExit) {
        obj.onExit(message);
      }
    } else if (
      event.data &&
      event.data.type &&
      event.data.type.startsWith('sp-success')
    ) {
      const { message } = event.data;
      console.log('Paid successfully!'); // skipcq: JS-0002
      if (obj && obj.onComplete) {
        obj.onComplete(message);
      }
    }
  } else {
    // The data was not sent from your site!
    console.log(event.origin); // skipcq: JS-0002
  }
}

class SwifterPay {
  sessionID?: string;
  onComplete?: (message: string) => void;
  onExit?: (response: ISessionError) => void;
  selector: string;
  renderInModal: boolean;
  messageListenerFunction?: (event: MessageEvent) => void;
  env: IEnv;
  themeID?: string;

  constructor() {
    this.sessionID = undefined;
    this.onComplete = undefined;
    this.onExit = undefined;
    this.selector = 'body';
    this.renderInModal = true;
    this.messageListenerFunction = respondToIframeMessage.bind(null, this);
    this.env = null;
    this.themeID = undefined;
  }

  _registerMethods(startObj: ISession) {
    const user_defined_complete_func = startObj.onComplete;
    this.onComplete = function (message: string) {
      if (!user_defined_complete_func) return;
      user_defined_complete_func(message);
      closeSwifterPayModal();
      if (this.messageListenerFunction) {
        window.removeEventListener(
          'message',
          this.messageListenerFunction,
          false
        );
      }
    };
    const user_defined_exit_func = startObj.onExit;
    this.onExit = function (response: ISessionError) {
      if (!user_defined_exit_func) return;
      user_defined_exit_func(response);
      closeSwifterPayModal();

      if (this.messageListenerFunction) {
        window.removeEventListener(
          'message',
          this.messageListenerFunction,
          false
        );
      }
    };

    if (this.messageListenerFunction) {
      window.addEventListener('message', this.messageListenerFunction);
    }
  }

  // skipcq: JS-0045
  startSession(startObj: ISession) {
    this.sessionID = startObj.sessionID;
    this.env = startObj.env || null;
    this.themeID = startObj.themeID;

    if (startObj.selector !== undefined) {
      this.selector = startObj.selector;
    }

    if (startObj.renderInModal !== undefined) {
      this.renderInModal = startObj.renderInModal;
    }

    this._registerMethods(startObj);

    if (!this.sessionID) {
      console.error('The session is missing. Please notify the admin.'); // skipcq: JS-0002
      if (this.onExit) {
        return this.onExit({ code: 'init_error' });
      }
      return false;
    }

    _init(
      this.sessionID,
      this.selector,
      this.renderInModal,
      this.env,
      this.themeID
    );
  }

  signup(startObj: ISignup) {
    this.env = startObj.env || null;
    this.themeID = startObj.themeID;

    if (startObj.selector !== undefined) {
      this.selector = startObj.selector;
    }

    if (startObj.renderInModal !== undefined) {
      this.renderInModal = startObj.renderInModal;
    }

    this._registerMethods(startObj);

    _initRegistration(
      this.selector,
      this.renderInModal,
      this.env,
      this.themeID
    );
  }
}

declare global {
  interface Window {
    SwifterPay: ISwifterPayConstructor;
    SparcPay: ISwifterPayConstructor;
  }
}

window.SwifterPay = SwifterPay;
window.SparcPay = SwifterPay; //Fallback to support older version
